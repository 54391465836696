import { inject } from '@angular/core';
import { signalStore, withState } from '@ngrx/signals';
import { map, switchMap } from 'rxjs';

import { BillingHttpService } from '@bto/billing/services/http/billing-http.service';
import { PricingPlanResponse } from '@bto/billing/types/plan.types';
import {
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  Balance,
  SubscriptionInfo,
} from '@bto/billing/types/subscription.types';
import { handlePostRequest, handleRequest, requestPayload } from '@bto/shared/utils/store.utils';
import { injectStoreWithDispatch } from '@lib/ngrx-signal-redux/inject-dispatch';
import { emptyProps, eventGroup, Events, props, when, withEffects, withReducer } from '@ngrx/signals/events';

export const billingSource = 'billing';

export const billingEvents = eventGroup({
  source: billingSource,
  events: {
    fetchPricingPlans: emptyProps(),
    createSubscription: requestPayload<CreateSubscriptionRequest, CreateSubscriptionResponse>(),
    getBalance: emptyProps(),
    getSubscription: emptyProps(),
  },
});

export const billingApiEvents = eventGroup({
  source: billingSource,
  events: {
    fetchPricingPlansSuccess: props<{ plans: PricingPlanResponse }>(),
    createSubscriptionSuccess: emptyProps(),
    getBalanceSuccess: props<{ balance: Balance }>(),
    getSubscriptionSuccess: props<{ subscription: SubscriptionInfo }>(),
  },
});

export const BillingStore = signalStore(
  { providedIn: 'root' },
  withState({
    plans: undefined as PricingPlanResponse | undefined,
    balance: undefined as Balance | undefined,
    subscription: undefined as SubscriptionInfo | undefined,
  }),
  withReducer(
    billingSource,
    when(billingApiEvents.fetchPricingPlansSuccess, ({ plans }) => ({ plans })),
    when(billingApiEvents.getBalanceSuccess, ({ balance }) => ({ balance })),
    when(billingApiEvents.getSubscriptionSuccess, ({ subscription }) => ({ subscription }))
  ),
  withEffects((_, events = inject(Events), billingHttpService = inject(BillingHttpService)) => ({
    fetchPricingPlans$: events.on(billingEvents.fetchPricingPlans).pipe(
      switchMap(handleRequest(billingHttpService.getPricingPlans)),
      map(plans => billingApiEvents.fetchPricingPlansSuccess({ plans }))
    ),
    createSubscription$: events.on(billingEvents.createSubscription).pipe(
      switchMap(handlePostRequest(billingHttpService.createSubscription)),
      map(() => billingApiEvents.createSubscriptionSuccess())
    ),
    getBalance$: events.on(billingEvents.getBalance).pipe(
      switchMap(handleRequest(billingHttpService.getBalance)),
      map(balance => billingApiEvents.getBalanceSuccess({ balance }))
    ),
    getSubscription$: events.on(billingEvents.getSubscription).pipe(
      switchMap(handleRequest(billingHttpService.getSubscription)),
      map(subscription => billingApiEvents.getSubscriptionSuccess({ subscription }))
    ),
  }))
);

export const injectBillingStore = () => injectStoreWithDispatch(BillingStore, billingEvents);
