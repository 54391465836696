<bto-loader-wrapper
  *transloco="let t; read: 'billing.balance'"
  class="flex flex-col gap-4 items-center"
  [operationIds]="'getBalance'">
  <bto-balance-progress [label]="'subscription'" [balancePart]="balance()?.subscription" />

  <bto-balance-progress [label]="'extra'" [balancePart]="balance()?.top_up" />

  <div class="flex gap-2 w-full" [class.justify-end]="!isInSettings()">
    <button btoBtn brnDialogClose (click)="topUp()">
      <bto-icon name="lucidePlus" class="mr-2" size="sm"></bto-icon>
      {{ t('buttons.topUp') }}
    </button>
  </div>
</bto-loader-wrapper>
