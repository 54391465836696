import { Component, inject, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { provideIcons } from '@ng-icons/core';
import { lucidePlus } from '@ng-icons/lucide';
import { BrnDialogCloseDirective } from '@spartan-ng/ui-dialog-brain';

import { BalanceProgressComponent } from '@bto/billing/portals/subscription-status/subscription-progress/balance-progress.component';
import { TopUpDialogComponent } from '@bto/billing/portals/subscription-status/top-up-dialog/top-up-dialog.component';
import { injectBillingStore } from '@bto/billing/store/billing.store';
import { LoaderWrapperComponent } from '@lib/loader-wrapper';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import { HlmDialogService } from '@lib/ui-dialog-helm/src';
import { HlmIconComponent } from '@lib/ui-icon-helm/src';

@Component({
  selector: 'bto-balance-popover',
  standalone: true,
  imports: [
    BalanceProgressComponent,
    LoaderWrapperComponent,
    HlmIconComponent,
    HlmButtonDirective,
    TranslocoDirective,
    BrnDialogCloseDirective,
  ],
  providers: [provideIcons({ lucidePlus })],
  templateUrl: 'balance-popover.component.html',
})
export class BalancePopoverComponent {
  private readonly store = injectBillingStore();
  private readonly hlmDialogService = inject(HlmDialogService);

  isInSettings = input(false);

  balance = this.store.balance;

  constructor() {
    this.store.getBalance();
  }

  topUp() {
    this.hlmDialogService.open(TopUpDialogComponent, {});
  }
}
