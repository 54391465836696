import { inject, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { map, Subject } from 'rxjs';

import { AppRouteNames } from '@bto/app.route-names';
import { MenuItem, TranslocoMenuItem } from '@bto/shared/layout/header/menu-items.type';
import { TranslocoScopesEnum } from '@bto/shared/transloco/transloco-scopes';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  private readonly router = inject(Router);
  private readonly translocoService = inject(TranslocoService);

  itemClicked = new Subject<string>();

  getCurrentRoute(): AppRouteNames {
    return (this.router.url?.split(/[\/#]/)[1] as AppRouteNames) || AppRouteNames.LANDING;
  }

  getItems(): Signal<MenuItem[] | undefined> {
    const module = this.getCurrentRoute() || TranslocoScopesEnum.LANDING;
    return toSignal(
      this.translocoService
        .selectTranslateObject<Record<string, TranslocoMenuItem>>(`menuItems`, {}, module)
        .pipe(
          map(items =>
            typeof items != 'object' ? [] : Object.entries(items).map(this.mapTranslatedItemToMenuItem.bind(this))
          )
        )
    );
  }

  private mapTranslatedItemToMenuItem([code, propsOrLabel]: [string, TranslocoMenuItem | string]): MenuItem {
    const props = typeof propsOrLabel === 'string' ? { label: propsOrLabel } : propsOrLabel;
    const propsWithMappedChildren = {
      ...props,
      children: props.children
        ? Object.entries(props.children)?.map(this.mapTranslatedItemToMenuItem.bind(this))
        : undefined,
    };
    if (code.startsWith('#')) {
      return { code: '/', fragment: code.split('#')[1] || '', ...propsWithMappedChildren };
    }
    return { code, ...propsWithMappedChildren };
  }
}
