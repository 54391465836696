import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { provideIcons } from '@ng-icons/core';
import { lucideCog, lucideLogOut, lucideMoon, lucideSun, lucideUser, lucideWallet } from '@ng-icons/lucide';
import { BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { BrnPopoverImports } from '@spartan-ng/ui-popover-brain';

import { injectAuthStore } from '@bto/auth/store/auth.store';
import { Account } from '@bto/auth/types/account.types';
import { ThemeService } from '@bto/shared/services/theme.service';
import { SettingsDialogComponent } from '@bto/shared/settings/settings-dialog/settings-dialog.component';
import { HlmAvatarComponent, HlmAvatarFallbackDirective } from '@lib/ui-avatar-helm/src';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import { HlmDialogService } from '@lib/ui-dialog-helm/src';
import { HlmIconComponent } from '@lib/ui-icon-helm/src';
import {
  HlmMenuComponent,
  HlmMenuGroupComponent,
  HlmMenuItemDirective,
  HlmMenuSeparatorComponent,
} from '@lib/ui-menu-helm/src';
import { HlmPopoverImports } from '@lib/ui-popover-helm/src';

@Component({
  selector: 'bto-header-avatar',
  standalone: true,
  imports: [
    HlmAvatarComponent,
    HlmAvatarFallbackDirective,
    BrnPopoverImports,
    HlmPopoverImports,
    HlmButtonDirective,
    TranslocoDirective,
    HlmIconComponent,
    NgTemplateOutlet,
    HlmMenuComponent,
    HlmMenuSeparatorComponent,
    HlmMenuItemDirective,
    BrnMenuTriggerDirective,
    HlmMenuGroupComponent,
  ],
  providers: [
    provideIcons({ lucideUser, lucideWallet, lucideCog, lucideLogOut, lucideMoon, lucideSun }),
    HlmDialogService,
  ],
  templateUrl: 'header-avatar.component.html',
  host: {
    class: 'flex items-center justify-center',
  },
})
export class HeaderAvatarComponent {
  private readonly authStore = injectAuthStore();
  private readonly themeService = inject(ThemeService);
  private readonly dialogService = inject(HlmDialogService);

  account = input.required<Account>();
  avatarLetter = computed(() => this.account().owner.email[0].toUpperCase());
  theme = this.themeService.darkMode$;

  logout() {
    this.authStore.logout();
  }

  openSettings() {
    this.dialogService.open(SettingsDialogComponent, { initialTab: 'general' });
  }

  switchTheme() {
    this.themeService.setDarkMode(this.theme() === 'dark' ? 'light' : 'dark');
  }

  openBilling() {
    this.dialogService.open(SettingsDialogComponent, { initialTab: 'billing' });
  }
}
