import { Component, computed } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { provideIcons } from '@ng-icons/core';
import { lucideCircleX } from '@ng-icons/lucide';
import { BrnAlertDialogImports } from '@spartan-ng/ui-alertdialog-brain';
import { BrnDialogContext } from '@spartan-ng/ui-dialog-brain';

import { BalancePopoverComponent } from '@bto/billing/portals/subscription-status/balance-popover/balance-popover.component';
import { injectBillingStore } from '@bto/billing/store/billing.store';
import { LoaderWrapperComponent } from '@lib/loader-wrapper';
import { HlmAlertDialogImports } from '@lib/ui-alertdialog-helm/src';
import { HlmBadgeDirective } from '@lib/ui-badge-helm/src';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import { HlmIconComponent } from '@lib/ui-icon-helm/src';

@Component({
  selector: 'bto-billing-settings',
  standalone: true,
  imports: [
    BalancePopoverComponent,
    TranslocoDirective,
    HlmButtonDirective,
    HlmIconComponent,
    HlmAlertDialogImports,
    BrnAlertDialogImports,
    LoaderWrapperComponent,
    HlmBadgeDirective,
  ],
  providers: [provideIcons({ lucideCircleX })],
  templateUrl: 'billing-settings.component.html',
})
export class BillingSettingsComponent {
  private readonly store = injectBillingStore();

  subscription = this.store.subscription;

  activeUntil = computed(() => new Date(this.subscription()?.active_until || 0));

  constructor() {
    this.store.getSubscription();
  }

  cancel(ctx: BrnDialogContext<unknown>) {
    ctx.close();
  }
}
