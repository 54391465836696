import { Directive } from '@angular/core';

import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';

@Directive({
  selector: 'button[btoAlertDialogAction]',
  standalone: true,
  hostDirectives: [{ directive: HlmButtonDirective, inputs: ['variant'] }],
})
export class HlmAlertDialogActionButtonDirective {}
