export type CreateTopUpRequest = {
  amount_of_hours: number;
};

export enum TopUpStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export type TopUpState = {
  id: string;
  status: TopUpStatus;
  processing_time: number;
  created: string;
};
