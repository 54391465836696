import type { ComponentType } from '@angular/cdk/portal';
import { Injectable, type TemplateRef, inject, Type, ViewContainerRef } from '@angular/core';
import {
  type BrnDialogOptions,
  BrnDialogService,
  DEFAULT_BRN_DIALOG_OPTIONS,
  cssClassesToArray,
  BrnDialogRef,
} from '@spartan-ng/ui-dialog-brain';

import { AbstractDialogComponent } from '@lib/ui-dialog-helm/src/lib/abstract-dialog.component';

import { HlmDialogContentComponent } from './hlm-dialog-content.component';
import { hlmDialogOverlayClass } from './hlm-dialog-overlay.directive';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HlmDialogOptions<DialogContext = any> = BrnDialogOptions & {
  contentClass?: string;
  context?: DialogContext;
};

@Injectable()
export class HlmDialogService {
  private readonly _brnDialogService = inject(BrnDialogService);
  private readonly viewContainerRef = inject(ViewContainerRef, { optional: true }) || undefined;

  public open<
    T extends AbstractDialogComponent<any>,
    Context extends T extends AbstractDialogComponent<infer ComponentContext> ? ComponentContext : never,
    Result extends T extends AbstractDialogComponent<any, infer ComponentResult> ? ComponentResult : never,
  >(
    component: Type<T> & { TYPE: string },
    context: Context,
    options?: Partial<HlmDialogOptions>
  ): BrnDialogRef<Result> {
    return this.internalOpen(component, {
      ...options,
      contentClass: `dialog--${component.TYPE}`,
      context,
    }) as BrnDialogRef<Result>;
  }

  private internalOpen(component: ComponentType<unknown> | TemplateRef<unknown>, options?: Partial<HlmDialogOptions>) {
    options = {
      ...DEFAULT_BRN_DIALOG_OPTIONS,
      closeDelay: 100,
      // eslint-disable-next-line
      ...(options ?? {}),
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      backdropClass: cssClassesToArray(`${hlmDialogOverlayClass} ${options?.backdropClass ?? ''}`),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      context: { ...options?.context, $component: component, $dynamicComponentClass: options?.contentClass },
    };
    return this._brnDialogService.open(HlmDialogContentComponent, this.viewContainerRef, options.context, options);
  }
}
