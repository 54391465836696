<div
  *transloco="let t; read: 'header'"
  class="flex items-center justify-between w-full mx-auto h-[56px]"
  [class.max-w-screen-xl]="!fullWidth()">
  <nav class="flex items-center flex-grow gap-1 min-w-0">
    <a btoBtn variant="ghost" class="hidden sm:flex" [routerLink]="homeLink">
      <bto-logo [size]="2" />
      <span class="sr-only">{{ t('logo') }}</span>
    </a>

    <bto-mobile-nav class="sm:hidden" />

    <div class="hidden sm:flex sm:space-x-2">
      @for (item of items(); track item) {
        @if (item.children) {
          <bto-context-menu-wrapper
            [isLeftButton]="true"
            [items]="item.children"
            [listenToDocument]="true"
            (itemClicked)="itemClicked($event.code)">
            <button btoBtn size="sm" variant="link" class="opacity-85 font-bold">{{ item.label }}</button>
          </bto-context-menu-wrapper>
        } @else {
          <a
            class="opacity-85 font-bold"
            btoBtn
            size="sm"
            variant="ghost"
            routerLinkActive="!opacity-100"
            [routerLink]="item.code"
            [fragment]="item.fragment"
            >{{ item.label }}</a
          >
        }
      }
    </div>

    <ng-content></ng-content>
  </nav>

  <div class="grid gap-2 grid-cols-2 min-w-max">
    @if (authStore.account(); as account) {
      @if (authStore.isSubscribed()) {
        <bto-balance-status />
      }
      <bto-header-avatar class="justify-self-center w-[40px]" [account]="account" />
    } @else {
      <button btoBtn variant="link" (click)="login()">{{ t('buttons.signIn') }}</button>
      <button btoBtn variant="secondary" (click)="register()">{{ t('buttons.signUp') }}</button>
    }
  </div>
</div>
