<div
  *transloco="let t; read: 'billing.balance'"
  class="flex flex-col w-full"
  [btoTooltipTrigger]="renewalDate() ? t('renewsOn', { date: renewalDate() }) : null"
  [tooltipContentClasses]="'relative top-6'"
  [position]="'above'">
  <div class="flex justify-between gap-1">
    <small class="font-medium leading-none self-start">{{ t(label()) }}</small>

    <small class="text-xs leading-5">{{
      t('includedTime', {
        remainingTime: t('formattedTime', { hours: remainingHours(), minutes: remainingMinutes() }).trim() || 0,
        totalTime: t('formattedTime', { hours: totalHours(), minutes: totalMinutes() }).trim() || 0,
      })
    }}</small>
  </div>

  <brn-progress
    btoTooltipTrigger
    class="w-full bg-mild rounded-full h-3 overflow-hidden relative inline-flex"
    [value]="processingTimePercentage()">
    <brn-progress-indicator bto [value]="processingTimePercentage() || null" [animated]="false" />
  </brn-progress>
</div>
