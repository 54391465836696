<bto-sheet side="left" closeDelay="100">
  <button size="sm" id="menu-trigger" variant="ghost" brnSheetTrigger btoBtn>
    <bto-icon name="lucideMenu" size="sm" />
    <span class="sr-only">Open menu</span>
  </button>
  <bto-sheet-content *brnSheetContent="let ctx" class="pb-0 pr-0">
    <button btoSheetClose>
      <span class="sr-only">Close</span>
      <bto-icon class="flex w-4 h-4" name="lucideX" />
    </button>
    <div class="flex items-center pb-2">
      <a btoBtn variant="ghost" class="mr-4 p-1.5" routerLink="/" (click)="ctx.close()">
        <bto-logo class="text-primary" [size]="2" />
      </a>
      <span>Beep That Out</span>
    </div>
    <bto-scroll-area class="h-[calc(100vh-8rem)]">
      <div class="flex flex-col p-2 pb-4 space-y-1">
        @for (item of items(); track item.code) {
          <a class="px-2 py-1 text-foreground hover:underline" [routerLink]="item.code" (click)="ctx.close()">{{
            item.label
          }}</a>
        }
      </div>
    </bto-scroll-area>
  </bto-sheet-content>
</bto-sheet>
