import { inject } from '@angular/core';
import { BrnDialogRef, injectBrnDialogContext } from '@spartan-ng/ui-dialog-brain';

export class AbstractDialogComponent<Context = undefined, Result = undefined> {
  static TYPE: 'default' | 'medium' | 'large' = 'default';

  protected readonly context = injectBrnDialogContext<Context>();
  protected readonly dialogRef = inject<BrnDialogRef<Result>>(BrnDialogRef);

  close(result?: Result) {
    this.dialogRef.close(result);
  }
}
