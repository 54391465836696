import { Component, computed, DestroyRef, inject, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { translate, TranslocoDirective } from '@jsverse/transloco';
import { BrnDialogCloseDirective } from '@spartan-ng/ui-dialog-brain';
import { BrnToggleDirective, BrnToggleGroupComponent } from '@spartan-ng/ui-toggle-brain';
import { toast } from 'ngx-sonner';

import { BillingHttpService } from '@bto/billing/services/http/billing-http.service';
import { LoaderWrapperComponent } from '@lib/loader-wrapper';
import { BtoCurrencyPipe } from '@lib/pipes/currency.pipe';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import {
  HlmDialogDescriptionDirective,
  HlmDialogFooterComponent,
  HlmDialogHeaderComponent,
  HlmDialogTitleDirective,
} from '@lib/ui-dialog-helm/src';
import { AbstractDialogComponent } from '@lib/ui-dialog-helm/src/lib/abstract-dialog.component';
import { HlmFormFieldComponent, HlmInputDirective } from '@lib/ui-formfield-helm/src';
import { HlmLabelDirective } from '@lib/ui-label-helm/src';
import { HlmToggleDirective, HlmToggleGroupDirective } from '@lib/ui-toggle-helm/src';
import { handleErrors } from '@shared/operators/operators';

@Component({
  selector: 'bto-top-up-dialog',
  standalone: true,
  imports: [
    HlmDialogHeaderComponent,
    HlmDialogTitleDirective,
    HlmLabelDirective,
    HlmInputDirective,
    HlmButtonDirective,
    HlmDialogFooterComponent,
    TranslocoDirective,
    HlmDialogDescriptionDirective,
    FormsModule,
    BrnDialogCloseDirective,
    HlmFormFieldComponent,
    BrnToggleGroupComponent,
    BrnToggleDirective,
    HlmToggleGroupDirective,
    HlmToggleDirective,
    BtoCurrencyPipe,
    LoaderWrapperComponent,
  ],
  templateUrl: 'top-up-dialog.component.html',
})
export class TopUpDialogComponent extends AbstractDialogComponent<{ message?: string }> {
  private readonly billingHttpService = inject(BillingHttpService);
  private readonly destroyRef = inject(DestroyRef);

  private readonly form = viewChild(NgForm);

  readonly TRANSLOCO_KEY = 'billing.balance.topUp';
  readonly TOP_UP_TYPES = [5, 10, 20, 50, 'other'] as const;

  topUpType = signal<(typeof this.TOP_UP_TYPES)[number]>(10);
  otherTime = signal<number>(5);

  processingHours = computed(() => (this.topUpType() !== 'other' ? (this.topUpType() as number) : this.otherTime()));
  additionalProcessingTimeCost = computed(() => ({ value: 119, currency: 'USD' })); // TODO: get from API
  totalCost = computed(() => this.processingHours() * this.additionalProcessingTimeCost().value);

  topUp() {
    if (!this.form()?.valid) return;

    this.billingHttpService
      .topUp({ amount_of_hours: this.processingHours() })
      .pipe(takeUntilDestroyed(this.destroyRef), handleErrors())
      .subscribe(() => {
        this.close();
        toast.success(translate(this.TRANSLOCO_KEY + '.success', { hours: this.processingHours() }));
      });
  }
}
