import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { lucideMenu, lucideX } from '@ng-icons/lucide';
import { BrnSheetContentDirective, BrnSheetTriggerDirective } from '@spartan-ng/ui-sheet-brain';

import { BtoLogoComponent } from '@bto/shared/components/logo.component';
import { MenuItemsService } from '@bto/shared/layout/header/menu-items.service';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import { HlmIconComponent } from '@lib/ui-icon-helm/src';
import { HlmScrollAreaComponent } from '@lib/ui-scrollarea-helm/src';
import { HlmSheetImports } from '@lib/ui-sheet-helm/src';

// Stolen from https://github.com/goetzrobin/spartan/tree/main/apps/app/src/app/shared/header

@Component({
  selector: 'bto-mobile-nav',
  standalone: true,
  imports: [
    BrnSheetTriggerDirective,
    BrnSheetContentDirective,
    HlmSheetImports,

    HlmButtonDirective,
    HlmIconComponent,
    HlmScrollAreaComponent,
    RouterLink,
    BtoLogoComponent,
  ],
  providers: [provideIcons({ lucideMenu, lucideX })],
  templateUrl: 'header-mobile-nav.component.html',
})
export class HeaderMobileNavComponent {
  items = inject(MenuItemsService).getItems();
}
