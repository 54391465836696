<bto-loader-wrapper
  *transloco="let t; read: 'billing.settings'"
  class="flex flex-col gap-5"
  [operationIds]="['getSubscription', 'cancelSubscription']">
  @if (subscription(); as subscription) {
    <section class="flex flex-col gap-3">
      <div class="flex gap-2">
        <h5>{{ t('headings.type') }}</h5>
        <span btoBadge [variant]="'secondary'">{{ t('typeBadge.' + subscription.subscription_type) }}</span>
      </div>
      <small>{{ t('descriptions.type.' + subscription.subscription_type, { date: activeUntil() }) }}</small>
    </section>
  }

  <section class="flex flex-col gap-3">
    <h5>{{ t('headings.usage') }}</h5>
    <small>{{ t('descriptions.usage') }}</small>
    <bto-balance-popover [isInSettings]="true" />
  </section>

  <section class="flex flex-col gap-3">
    <h5>{{ t('headings.cancel') }}</h5>
    <small>{{ t('descriptions.cancel') }}</small>

    <bto-alert-dialog>
      <button btoBtn brnAlertDialogTrigger variant="destructive">
        <bto-icon name="lucideCircleX" class="mr-2" size="sm"></bto-icon>
        {{ t('buttons.cancel') }}
      </button>
      <bto-alert-dialog-content *brnAlertDialogContent="let ctx">
        <bto-alert-dialog-header>
          <h3 btoAlertDialogTitle>{{ t('cancel-confirm.title') }}</h3>
          <p btoAlertDialogDescription>{{ t('cancel-confirm.description') }} </p>
        </bto-alert-dialog-header>
        <bto-alert-dialog-footer>
          <button btoAlertDialogAction (click)="ctx.close()">{{ t('buttons.comeBack') }}</button>
          <button btoAlertDialogAction [variant]="'destructive'" (click)="cancel(ctx)">{{
            t('buttons.cancel')
          }}</button>
        </bto-alert-dialog-footer>
      </bto-alert-dialog-content>
    </bto-alert-dialog>
  </section>
</bto-loader-wrapper>
