import { Component } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { provideIcons } from '@ng-icons/core';
import { lucideWallet } from '@ng-icons/lucide';
import { BrnPopoverImports } from '@spartan-ng/ui-popover-brain';

import { BalancePopoverComponent } from '@bto/billing/portals/subscription-status/balance-popover/balance-popover.component';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import { HlmIconComponent } from '@lib/ui-icon-helm/src';
import { HlmPopoverImports } from '@lib/ui-popover-helm/src';

@Component({
  selector: 'bto-balance-status',
  standalone: true,
  imports: [
    HlmIconComponent,
    BrnPopoverImports,
    HlmPopoverImports,
    TranslocoDirective,
    HlmButtonDirective,
    BalancePopoverComponent,
  ],
  providers: [provideIcons({ lucideWallet })],
  templateUrl: 'balance-status.component.html',
})
export class BalanceStatusComponent {}
