<brn-popover *transloco="let t; read: 'header.avatar'" sideOffset="5" closeDelay="100">
  <button brnPopoverTrigger>
    <bto-avatar>
      <span class="bg-secondary text-white" btoAvatarFallback>{{ avatarLetter() }}</span>
    </bto-avatar>
    <span class="sr-only">{{ t('alt') }}</span>
  </button>

  <bto-menu *brnPopoverContent="let ctx" variant="menubar" class="w-48 mt-3.5 p-1" btoPopoverContent>
    <div class="px-2 py-1.5">
      <p class="leading-4 text-sm">{{ t('account') }}</p>
      <small class="block mt-1 text-ellipsis overflow-hidden">{{ account().owner.email }}</small>
    </div>

    <bto-menu-separator />

    <bto-menu-group>
      <button btoMenuItem (click)="openBilling()">
        <bto-icon name="lucideWallet" class="mr-2" size="sm"></bto-icon>
        {{ t('buttons.billing') }}
      </button>

      <button btoMenuItem (click)="openSettings()">
        <bto-icon name="lucideCog" class="mr-2" size="sm"></bto-icon>
        {{ t('buttons.settings') }}
      </button>

      <button btoMenuItem (click)="switchTheme()">
        <bto-icon class="mr-2" size="sm" [name]="theme() === 'dark' ? 'lucideSun' : 'lucideMoon'"></bto-icon>
        {{ t('buttons.switchTheme') }}
      </button>
    </bto-menu-group>

    <bto-menu-separator />

    <button btoMenuItem (click)="logout()">
      <bto-icon name="lucideLogOut" class="mr-2" size="sm"></bto-icon>
      {{ t('buttons.signOut') }}
    </button>
  </bto-menu>
</brn-popover>
