import { Component, inject, input } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { provideIcons } from '@ng-icons/core';
import { lucideGithub, lucideTwitter } from '@ng-icons/lucide';
import { BrnContextMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';

import { AppRouteNames } from '@bto/app.route-names';
import { AuthRouteNames } from '@bto/auth/auth.route-names';
import { injectAuthStore } from '@bto/auth/store/auth.store';
import { BalanceStatusComponent } from '@bto/billing/portals/subscription-status/balance-status.component';
import { BtoLogoComponent } from '@bto/shared/components/logo.component';
import { HeaderAvatarComponent } from '@bto/shared/layout/header/components/header-avatar/header-avatar.component';
import { MenuItemsService } from '@bto/shared/layout/header/menu-items.service';
import { navigate, route } from '@bto/shared/utils/route.utils';
import { BrnMenuTriggerDirective } from '@lib/brn-menu-trigger.directive';
import { ContextMenuWrapperComponent } from '@lib/context-menu-wrapper/context-menu-wrapper.component';
import {
  HlmMenuComponent,
  HlmMenuGroupComponent,
  HlmMenuItemDirective,
  HlmMenuShortcutComponent,
} from '@lib/ui-menu-helm/src';
import { HlmAvatarComponent } from '@spartan-ng/ui-avatar-helm';
import { HlmButtonDirective } from '@spartan-ng/ui-button-helm';
import { HlmIconComponent } from '@spartan-ng/ui-icon-helm';

import { HeaderMobileNavComponent } from './components/header-mobile-nav/header-mobile-nav.component';

// Stolen from https://github.com/goetzrobin/spartan/tree/main/apps/app/src/app/shared/header

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header[bto-header]',
  standalone: true,
  imports: [
    HlmButtonDirective,
    RouterLink,
    HlmIconComponent,
    HeaderMobileNavComponent,
    BtoLogoComponent,
    HlmAvatarComponent,
    TranslocoDirective,
    RouterLinkActive,
    HlmMenuComponent,
    HlmMenuGroupComponent,
    HlmMenuItemDirective,
    HlmMenuShortcutComponent,
    BrnContextMenuTriggerDirective,
    BrnMenuTriggerDirective,
    ContextMenuWrapperComponent,
    HeaderAvatarComponent,
    BalanceStatusComponent,
  ],
  providers: [provideIcons({ lucideTwitter, lucideGithub })],
  host: {
    class: 'block sticky w-full top-0 z-40 bg-background/95 bg-blur-lg p-1.5 sm:px-4 border-b border-border',
  },
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  private readonly menuItemsService = inject(MenuItemsService);
  private readonly router = inject(Router);
  readonly authStore = injectAuthStore();

  fullWidth = input(false);

  homeLink = route(AppRouteNames.HOME);

  items = this.menuItemsService.getItems();

  itemClicked(item: string) {
    this.menuItemsService.itemClicked.next(item);
  }

  login() {
    navigate(this.router, AppRouteNames.AUTH, AuthRouteNames.LOGIN);
  }

  register() {
    navigate(this.router, AppRouteNames.AUTH, AuthRouteNames.REGISTER);
  }
}
