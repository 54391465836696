import { DatePipe, DecimalPipe, NgTemplateOutlet } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { BrnProgressImports } from '@spartan-ng/ui-progress-brain';

import { Balance } from '@bto/billing/types/subscription.types';
import { HlmProgressIndicatorDirective } from '@lib/ui-progress-helm/src';
import { HlmTooltipTriggerDirective } from '@lib/ui-tooltip-helm/src';

@Component({
  selector: 'bto-balance-progress',
  standalone: true,
  imports: [
    BrnProgressImports,
    TranslocoDirective,
    HlmProgressIndicatorDirective,
    HlmTooltipTriggerDirective,
    DatePipe,
    NgTemplateOutlet,
    DecimalPipe,
  ],
  templateUrl: 'balance-progress.component.html',
  host: {
    class: 'w-full',
  },
})
export class BalanceProgressComponent {
  label = input.required<'subscription' | 'extra'>();
  balancePart = input.required<Balance['subscription'] | Balance['top_up'] | undefined>();

  total = computed(() => this.balancePart()?.total ?? 0);
  totalHours = computed(() => Math.floor(this.total() / 60));
  totalMinutes = computed(() => this.total() % 60);

  used = computed(() => this.balancePart()?.used ?? 0);
  remaining = computed(() => this.total() - this.used());
  remainingHours = computed(() => Math.floor(this.remaining() / 60));
  remainingMinutes = computed(() => this.remaining() % 60);
  renewalDate = computed(this.computeRenewalDate.bind(this));

  processingTimePercentage = computed(() => (this.total() ? (this.remaining() / this.total()) * 100 : 0));

  private computeRenewalDate() {
    const balancePart = this.balancePart();
    if (balancePart && 'ends_at' in balancePart) {
      return new Date(balancePart.ends_at);
    }
    return undefined;
  }
}
