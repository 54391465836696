<brn-popover *transloco="let t; read: 'billing.balance'; scope: 'billing'" sideOffset="5" closeDelay="100">
  <button brnPopoverTrigger variant="ghost" btoBtn class="w-10 p-1">
    <bto-icon [name]="'lucideWallet'" />

    <span class="sr-only">{{ t('alt') }}</span>
  </button>

  <div *brnPopoverContent="let ctx" #content="popoverContent" btoPopoverContent class="w-[300px] mt-2.5">
    <!-- This if is so that component is lazily created and recreated every time it is opened to update the state -->
    @if (content.state() !== 'closed') {
      <bto-balance-popover />
    }
  </div>
</brn-popover>
