import { Component, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { BrnDialogCloseDirective } from '@spartan-ng/ui-dialog-brain';

import { BillingSettingsComponent } from '@bto/billing/portals/billing-settings/billing-settings.component';
import { HlmButtonDirective } from '@lib/ui-button-helm/src';
import {
  HlmDialogDescriptionDirective,
  HlmDialogFooterComponent,
  HlmDialogHeaderComponent,
  HlmDialogTitleDirective,
} from '@lib/ui-dialog-helm/src';
import { AbstractDialogComponent } from '@lib/ui-dialog-helm/src/lib/abstract-dialog.component';
import { HlmMenuItemDirective } from '@lib/ui-menu-helm/src';

export const SETTINGS_DIALOG_TABS = ['general', 'billing', 'security', 'notifications'] as const;
export type SettingsDialogTab = (typeof SETTINGS_DIALOG_TABS)[number];

export type SettingsDialogContext = {
  initialTab: SettingsDialogTab;
};

@Component({
  selector: 'bto-settings-dialog',
  standalone: true,
  imports: [
    HlmDialogHeaderComponent,
    HlmDialogTitleDirective,
    HlmDialogFooterComponent,
    TranslocoDirective,
    HlmDialogDescriptionDirective,
    BrnDialogCloseDirective,
    HlmMenuItemDirective,
    HlmButtonDirective,
    BillingSettingsComponent,
  ],
  templateUrl: 'settings-dialog.component.html',
  host: {
    class: 'flex flex-col gap-4',
  },
})
export class SettingsDialogComponent extends AbstractDialogComponent<SettingsDialogContext> {
  static override TYPE = 'large' as const;

  activeTab = signal<SettingsDialogTab>(this.context.initialTab);
  tabs = SETTINGS_DIALOG_TABS;

  readonly TRANSLOCO_KEY = 'settingsDialog';
}
