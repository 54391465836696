<ng-container *transloco="let t; read: TRANSLOCO_KEY">
  <bto-dialog-header>
    <h3 btoDialogTitle>{{ t('tabs.' + activeTab() + '.heading') }}</h3>
    <p btoDialogDescription>{{ t('tabs.' + activeTab() + '.description') }}</p>
  </bto-dialog-header>
  <hr class="hr" />
  <div class="flex flex-col lg:flex-row gap-8">
    <aside class="lg:min-w-[160px] lg:max-w-[160px]">
      <nav class="flex flex-wrap gap-1 lg:flex-col">
        @for (tab of tabs; track tab) {
          <button
            btoBtn
            variant="link"
            class="justify-start py-2 h-auto"
            [class.bg-muted]="activeTab() === tab"
            (click)="activeTab.set(tab)"
            >{{ t('tabs.' + tab + '.heading') }}</button
          >
        }
      </nav>
    </aside>

    <section class="flex-grow flex flex-col gap-3 h-[400px] overflow-y-auto p-2">
      @switch (activeTab()) {
        @case ('general') {}
        @case ('security') {}
        @case ('billing') {
          <bto-billing-settings />
        }
        @case ('notifications') {}
      }
    </section>
  </div>
</ng-container>
