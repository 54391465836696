import { Pipe, PipeTransform } from '@angular/core';
import { BaseLocalePipe, Currency, NumberFormatOptions } from '@jsverse/transloco-locale';

@Pipe({
  standalone: true,
  pure: false,
  name: 'btoCurrency',
})
export class BtoCurrencyPipe extends BaseLocalePipe implements PipeTransform {
  transform(
    value: number,
    currency?: Currency,
    display?: 'code' | 'symbol' | 'narrowSymbol' | 'name',
    numberFormatOptions?: NumberFormatOptions
  ): string {
    return this.localeService.localizeNumber(value / 100, 'currency', undefined, {
      currency,
      currencyDisplay: display,
      ...numberFormatOptions,
    });
  }
}
