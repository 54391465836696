import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import { PricingPlanResponse } from '@bto/billing/types/plan.types';
import {
  CreateSubscriptionRequest,
  CreateSubscriptionResponse,
  Balance,
  SubscriptionInfo,
} from '@bto/billing/types/subscription.types';
import { CreateTopUpRequest, TopUpState, TopUpStatus } from '@bto/billing/types/top-up.types';
import { repeatUntil } from '@bto/shared/operators/operators';
import { BaseHttpService } from '@bto/shared/services/base-http.service';

@Injectable({ providedIn: 'root' })
export class BillingHttpService extends BaseHttpService {
  basePath = 'billing';

  getPricingPlans = () => {
    return this.get<PricingPlanResponse>('pricing-plans', 'fetchPricingPlans');
  };

  createSubscription = (request: CreateSubscriptionRequest) => {
    return this.post<CreateSubscriptionRequest, CreateSubscriptionResponse>(
      'subscription',
      request,
      'createSubscription'
    );
  };

  getSubscription = (): Observable<SubscriptionInfo> => {
    return this.get<SubscriptionInfo>('subscription', 'getSubscription');
  };

  cancelSubscription = (): Observable<void> => {
    return this.post<undefined, void>('subscription/cancel', undefined, 'cancelSubscription');
  };

  getBalance = (): Observable<Balance> => {
    return this.get<Balance>('balance', 'getBalance');
  };

  topUp = (request: CreateTopUpRequest): Observable<void> => {
    return this.createTopUp(request).pipe(
      switchMap(({ id, status }) => {
        return status === TopUpStatus.SUCCESS
          ? of(undefined)
          : this.getTopUp(id).pipe(
              repeatUntil(({ status }) => {
                return status === TopUpStatus.SUCCESS;
              }, 'topUp'),
              map(() => {
                return undefined;
              })
            );
      })
    );
  };

  createTopUp = (request: CreateTopUpRequest): Observable<TopUpState> => {
    return this.post<CreateTopUpRequest, TopUpState>('top-up', request, 'createTopUp');
  };

  getTopUp = (id: string): Observable<TopUpState> => {
    return this.get<TopUpState>(`top-up/${id}`, 'getTopUp');
  };
}
