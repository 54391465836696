<bto-loader-wrapper *transloco="let t; read: TRANSLOCO_KEY" [operationIds]="['createTopUp', 'topUp']">
  <bto-dialog-header>
    <h3 btoDialogTitle>{{ t('title') }}</h3>
    <p btoDialogDescription>{{
      context.message ||
        t('description', {
          cost: additionalProcessingTimeCost().value | btoCurrency: additionalProcessingTimeCost().currency,
        })
    }}</p>
  </bto-dialog-header>
  <form #form="ngForm" class="mt-2">
    <div class="py-4 flex flex-col gap-4">
      <brn-toggle-group bto name="type" class="gap-1.5" [(ngModel)]="topUpType">
        @for (type of TOP_UP_TYPES; track type) {
          <button variant="outline" btoToggle brnToggle [value]="type">{{ t('type.' + type) }}</button>
        }
      </brn-toggle-group>

      @if (topUpType() === 'other') {
        <bto-form-field [errorTranslocoKey]="TRANSLOCO_KEY + '.errors'">
          <label btoLabel>{{ t('labels.time') }} </label>
          <input
            btoInput
            required
            name="time"
            type="number"
            min="5"
            max="50"
            value="5"
            step="1"
            pattern="\d*"
            [(ngModel)]="otherTime" />
        </bto-form-field>
      }

      <h3 class="mt-3">{{
        t('total', { cost: totalCost() | btoCurrency: additionalProcessingTimeCost().currency })
      }}</h3>

      <small>{{ t('disclaimer') }}</small>
    </div>

    <bto-dialog-footer>
      <button btoBtn brnDialogClose>{{ t('buttons.cancel') }}</button>
      <button btoBtn variant="secondary" type="submit" (click)="topUp()">{{ t('buttons.topUp') }}</button>
    </bto-dialog-footer>
  </form>
</bto-loader-wrapper>
